<script lang="ts">
	import { Dialog, TransitionChild, TransitionRoot } from '@rgossiaux/svelte-headlessui';
	import { MODAL_SIZE_TO_REMOVE_WITHOUT_DESCRIPTION } from '../../../utils';
	import AtomButton from '../atoms/AtomButton.svelte';
	import AtomText from '../atoms/AtomText.svelte';
	import type { ButtonType, ButtonVariant } from '../models';

	type ModalButton = {
		text: string;
		variant: ButtonVariant;
		action: () => void;
		type?: ButtonType;
		disabled?: boolean;
		loading?: boolean;
		class?: string;
		hidden?: boolean;
		icon?: any;
	};

	export let open = false;
	export let title: string;
	export let description: string | undefined = undefined;
	export let color: 'success' | 'danger' = 'success';
	export let disableOnClose = false;
	export let firstButton: ModalButton | undefined = undefined;
	export let secondButton: ModalButton | undefined = undefined;
	export let thirdButton: ModalButton | undefined = undefined;
	export let formSubmit: (() => void) | undefined = undefined;
	export let icon: any = undefined;

	let descriptionDiv: HTMLDivElement | undefined = undefined; // This height can change so we the value from the source

	let completeButton = null as any;

	const iconColors = {
		success: {
			bg: 'bg-primary-100',
			icon: 'fill-primary-600'
		},
		danger: {
			bg: 'bg-red-100',
			icon: 'fill-red-600'
		}
	};

	// description.startsWith('modals.') when it is empty
	$: hasDescription = Boolean(description && !description.startsWith('modals.'));
</script>

<!-- It's good to have the TransitionRoot as absolute because when the modal is opened, it creates a div
and can add spacing between flex elements -->
<TransitionRoot show={open} class="absolute">
	<!-- z-[60] is more than z-[51] which is the notifications panel, it's good when you want to assign a portal to another user and the modals shows up -->
	<Dialog
		as="div"
		class="relative z-[60]"
		initialFocus={completeButton}
		on:close={() => {
			if (disableOnClose) return;

			open = false;
		}}
	>
		<TransitionChild
			enter="ease-out duration-300"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="ease-in duration-200"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
		>
			<div
				class="fixed top-0 bottom-0 left-0 right-0 bg-gray-500 bg-opacity-75 transition-opacity"
			/>
		</TransitionChild>

		<div class="fixed top-0 bottom-0 left-0 right-0 z-10 overflow-y-auto">
			<div
				class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
			>
				<TransitionChild
					enter="ease-out duration-300"
					enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					enterTo="opacity-100 translate-y-0 sm:scale-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100 translate-y-0 sm:scale-100"
					leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					class="w-full sm:w-[500px]"
				>
					<div
						class="w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"	
					>
						<form on:submit|preventDefault={formSubmit}>
							<div class="sm:flex sm:items-start" class:pb-4={!!$$slots.default}>
								{#if icon}
									<div
										class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10 mb-3
								{iconColors[color].icon} {iconColors[color].bg}"
									>
										<div class="h-6 w-6 flex justify-center items-center">
											<svelte:component this={icon} />
										</div>
									</div>
								{/if}
								<div class="text-center sm:mt-0 sm:text-left">
									<AtomText type="h5">{title}</AtomText>

									{#if hasDescription}
										<div bind:this={descriptionDiv} class="pt-2">
											<AtomText color="secondary">
												{description}
											</AtomText>
										</div>
									{/if}
								</div>
							</div>

							<div
								class="overflow-y-auto"
								style="max-height: {window.innerHeight -
									(MODAL_SIZE_TO_REMOVE_WITHOUT_DESCRIPTION +
										(descriptionDiv?.clientHeight || 0))}px"
							>
								<slot />
							</div>
							<div class="mt-5 sm:mt-4 flex flex-col sm:flex-row-reverse gap-2">
								{#if firstButton && !firstButton.hidden}
									<AtomButton
										text={firstButton.text}
										bind:this={completeButton}
										fullWidth="sm"
										variant={firstButton.variant}
										loading={firstButton.loading}
										disabled={firstButton.disabled}
										class={firstButton.class}
										icon={firstButton.icon}
										on:click={firstButton.action}
									/>
								{/if}
								{#if secondButton && !secondButton.hidden}
									<AtomButton
										text={secondButton.text}
										bind:this={completeButton}
										fullWidth="sm"
										variant={secondButton.variant}
										loading={secondButton.loading}
										disabled={secondButton.disabled}
										class={secondButton.class}
										icon={secondButton.icon}
										on:click={secondButton.action}
									/>
								{/if}
								{#if thirdButton && !thirdButton.hidden}
									<AtomButton
										text={thirdButton.text}
										bind:this={completeButton}
										fullWidth="sm"
										variant={thirdButton.variant}
										loading={thirdButton.loading}
										disabled={thirdButton.disabled}
										class={thirdButton.class}
										icon={thirdButton.icon}
										on:click={thirdButton.action}
									/>
								{/if}
							</div>
						</form>
					</div>
				</TransitionChild>
			</div>
		</div>
	</Dialog>
</TransitionRoot>
